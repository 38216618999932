var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _vm.showBtn
        ? _c(
            "c-box",
            {
              staticStyle: { "margin-bottom": "30px" },
              attrs: { slot: "header", flex: "row" },
              slot: "header"
            },
            [
              _c("importFile", {
                attrs: {
                  showBtn: true,
                  upLoadUrl: _vm.upLoadUrl,
                  downLoadUrl:
                    "http://benax.kindstaryouyi.com/apis/fileserver/download/root/FileStore/BenagenFile/template/主文库录入导入模板.xlsx"
                },
                on: { refresh: _vm.getData }
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { click: _vm.handleSample }
                },
                [_vm._v("新增样本")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { click: _vm.confirmSubmit }
                },
                [_vm._v("确认提交")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { click: _vm.temporarySave }
                },
                [_vm._v("暂存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { click: _vm.handleData }
                },
                [_vm._v("刷新")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { click: _vm.handleBatDel }
                },
                [_vm._v("批量删除")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.handlePrev }
                },
                [_vm._v("上一步")]
              ),
              _vm._t("default")
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          attrs: {
            data: _vm.list,
            height: _vm.height,
            border: "",
            "header-cell-style": { "text-align": "center" },
            "cell-style": { "text-align": "center" }
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _vm.showBtn
            ? _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { prop: "index", label: "序号", width: "60", fixed: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        scope.$index +
                          1 +
                          (_vm.pageObj.page - 1) * _vm.pageObj.pageSize
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "name", label: "文库名称" } }),
          _c("el-table-column", { attrs: { prop: "species", label: "物种" } }),
          _c("el-table-column", {
            attrs: { prop: "fragmentSize", label: "文库片段大小(bp)" }
          }),
          _c("el-table-column", {
            attrs: { prop: "concentration", label: "浓度(ng/ul)" }
          }),
          _c("el-table-column", {
            attrs: { prop: "volume", label: "体积(μL)" }
          }),
          _c("el-table-column", {
            attrs: { prop: "isBalance", label: "是否平衡文库" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm.isBalance[scope.row.isBalance].label))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "isCircular", label: "是否环化" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm.isCircular[scope.row.isCircular].label))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "sequencingPlatform", label: "测序平台" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.sequencingPlatform[scope.row.sequencingPlatform]
                          .label
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "sequencingStrategy", label: "测序策略" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.sequencingStrategy[scope.row.sequencingStrategy]
                          .label
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "sequencingData", label: "测序数据量" }
          }),
          _c("el-table-column", {
            attrs: { prop: "sequencingDataUnit", label: "数据量单位" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.sequencingDataUnit[scope.row.sequencingDataUnit]
                          .label
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "comment", label: "备注" } }),
          _vm.showSampleCode
            ? _c("el-table-column", {
                attrs: { prop: "sampleCode", label: "样本编码" }
              })
            : _vm._e(),
          _vm.showStorage
            ? _c("el-table-column", {
                attrs: { prop: "storage", label: "样本储位" }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "btns",
              label: "操作",
              width: "160",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.showSecondLibrary(scope.row)
                          }
                        }
                      },
                      [_vm._v("子文库")]
                    ),
                    _vm.showBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.showBtn
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#dc2e2a" },
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDel(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("c-pagination", { attrs: { page: _vm.pageObj, search: _vm.getData } }),
      _c("EditDialog", { ref: "EditDialog", on: { refresh: _vm.getData } }),
      _c(
        "SecondLibrary",
        {
          attrs: {
            visible: _vm.secondLibraryOption.visible,
            showBtn: _vm.secondLibraryOption.showBtn,
            libraryId: _vm.secondLibraryOption.libraryId,
            libraryName: _vm.secondLibraryOption.libraryName
          },
          on: { close: _vm.closeSecondLibrary }
        },
        [_vm._t("secondSlot")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }